import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

Turbolinks.start();
Rails.start();

document.addEventListener('turbolinks:load', () => {
  if (window.gtag) {
    window.gtag('js', new Date());
  }
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
});
